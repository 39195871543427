@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&family=Inter:wght@300&family=Lato:ital@1&family=Lobster+Two&family=Montserrat&family=Nunito:wght@400;500&family=Open+Sans&family=Poppins&family=Rajdhani:wght@400;700&family=Roboto&family=Saira+Extra+Condensed:wght@500;600;700&family=Secular+One&family=Source+Serif+Pro:ital,wght@0,200;1,400&display=swap');
.container-fluid {
    /* background-image: url('../../assets/images/Zoom out with syringe copy.jpg'); */
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height:100vh;
}


.display-full img {
    /* width: 100%; */
    width: -webkit-fill-available;
    transition: background-image 0.5s ease-in-out;
    /* height: 80%; */
}


.display-full video {
    /* width: 100%; */
    width: -webkit-fill-available;
    transition: background-image 0.5s ease-in-out;
    /* height: 80%; */
}

#on1, #on2{
    position: absolute;
    left: 4.5vw;
    top: 43vh;
    width: 10vmin;
    height: 21vmin;
   
}

#ok1,#ok2,#ok3{
    position: absolute;
    left: 32vw;
    top: 32%;
    width: 5%;
    height: 13vmin;
   
}

.mt-10{
margin-top:12%;
}

.digits span {
    cursor: pointer;
    /* margin: 0 5px; */
    font-size: 5vmin;
    border-right: 2px solid #000;
    /* border-left: 2px solid #000; */
    padding: 0px;
   

}



#mobile-portrait {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 999999;
    display: none;
}



.background-image-container {
    width: 100%;
    height: 100vh;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.background-image-container img {
    max-width: 100%;
    max-height: 100%;
}

