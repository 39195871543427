@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&family=Inter:wght@300&family=Lato:ital@1&family=Lobster+Two&family=Montserrat&family=Nunito:wght@400;500&family=Open+Sans&family=Poppins&family=Rajdhani:wght@400;700&family=Roboto&family=Saira+Extra+Condensed:wght@500;600;700&family=Secular+One&family=Source+Serif+Pro:ital,wght@0,200;1,400&display=swap');
.container-fluid {
    /* background-image: url('../../assets/images/REVISED DEVICE with Syringe 0011.jpg'); */
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height:100vh;
    /* position: relative; */
    /* width: 100%; */
    overflow: hidden;
    /* padding-top: 100%; */
}
.tab-content>.tab-pane{
    display:block !important
}
.display {
    width: 18%;
    height: 62%;
    /* left: 10.1%; */
    margin-top: 14.1%;
    /* left: 9.1%; */
    /* margin-left: 8.3%; */
    /* width: 246px;
    height: 244px;
    left: 9%;
    top: 29.2%; */
    /* width: 246px;
    height: 254px; */
    width: 16%;
    height: 35%;
    left: 9.1%;
    margin-top: 11.22%;
    /* margin-top: 14.1%; */
    /* background-image: url(./assets/images/Group\ 2.png); */
    background-repeat: no-repeat;
    transform: rotate(0deg);
}

    .displayVid {
        /* margin-top: 14.1%; */
        margin-top: 13.8%;
        /* left: 9.1%;
        margin-left: 8.6%; */
    }

    /* .displayVol{
        margin-top: 14%;
    } */


.display img {
    width: 100%;
    /* height: 80%; */
}
.display video {
    width: 100%;
    /* height: 86%; */
    /* margin-top:-10px; */
}



/* .tab-buttons {
  display: flex;
  flex-direction: row;
} */

.display ul li {
    list-style-type: none;
    padding-left: 10px;
}

/* .display ul,
.tab-content {
    position: absolute;
    left: 3%;
    top: 14%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 2vmin;
    line-height: 28px;
    color: #fff;
    width:90%;
   
} */

.row-view {
    display: flex;
    flex-direction: row;
}

.display ul .first {
    background: #D9D9D9;
    border-radius: 6px;
    color: #000000;
    width: 137%;
    border: 2px solid #000;
}

/* .display ul,
.tab-content1 {
    position: absolute;
    left: 3%;
    top: 30%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 126px;
    color: #fff;
} */

.mt-10{
margin-top:12%;
}

/* .meter {
    display: flex;
    align-items: center;
   position: absolute;
    left: 10%;
    top: 27%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 87px;
    color: #fff;
    text-align: center;
} */

/* .controls {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.digits {
    display: flex;
    align-items: center;
    border-bottom: 0px;
   
} */

.digits span {
    cursor: pointer;
    /* margin: 0 5px; */
    font-size: 5vmin;
    border-right: 2px solid #000;
    /* border-left: 2px solid #000; */
    padding: 0px;
   

}


/* 
.tab{
    width:26px;
} */
#tooltip{
    /* position:static;
    z-index:2; */
}
.digits span.active {
    color:transparent;
    background: #D9D9D9;
    border-radius: 6px;
    color: #000000;
    width: 68%;
    /* border: 1px solid #000;
    /* border:1px solid #000; */
}

.controls button {
    padding: 5px 10px;
    font-size: 16px;
}



/* .ok-button1{
    position: absolute;
    
    border-radius: 50%;
    height: 53%;
    width: 44%;
    background-color:transparent;
    color:transparent;
    border: 0px;
    left:20%;
} */

.mt-6{
    margin-top: 13%;
}
/* .down-button1{ 
    top: 69%;
    left: 186%;
} */

/* .up-button1{   
    top: -98%;
    left: 186%;
} */

/* .ok-button1{
    top: 32%; 
    left: 121%;
} */
#mobile-portrait {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 999999;
    display: none;
}

/* .left{
    top: -12%;
    left: 147%;
} */
/* .shiftright {
    left: 224%;
    top: -13%;
} */

/* .down-buttontime{ 
    left: 167%;
    top: 84%;
} */

/* .up-buttontime{   
    left: 165%;
    top: -77%;
} */

.ok-buttontime{
    top: 32%; 
    left: 121%;
}

/* .lefttime{
    left: 128%;
    top: 5%;
} */
/* .shiftrighttime {
    left: 202%;
    top: 4%;
} */


.background-image-container {
    width: 100%;
    height: 100vh;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.image-button{
    /* background-color: rgba(0, 0, 0, 0);
    color: rgba(255, 255, 255, 0); */
    background-color:transparent;
    color:transparent;
    border:0;
}
.background-image-container img {
    max-width: 100%;
    max-height: 100%;
}
/* top: 31%;
left: 89%;
transform: translate(-50%, -50%);
padding: 39PX 32px; */

.start-button{
    position: absolute;
    top: 31%;
left: 89%;
transform: translate(-50%, -50%);
padding: 39PX 32px;
    background-color:transparent;
    border-radius: 50%;
    color:transparent;
    font-size: 16px;
    cursor: pointer;
    border:0;
}
.button {
    position: absolute;
    top: 38%;
    left: 45%;
    transform: translate(-50%, -50%);
    padding: 15PX 15px;
    background-color:transparent;
    border-radius: 50%;
    color:transparent;
    font-size: 16px;
    cursor: pointer;
    border:0;
}

/* .image-container {
    position: absolute;
    top: 38%;
    left: 25%;
    transform: translate(-50%, -50%);
} */

/* .image-container img {
    width: 400px;
    height: auto;
} */
ul li{
    list-style-type: disclosure-closed;
    padding: 6px;
    color: #01655b;
}

a{
    font-size: 2.5vmin;
    /* padding: 77px 20px; */
    color: #01655b;
    text-decoration: none;
}

a:hover{

    text-decoration: underline;
}




.sidebar {
    width: 35vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    border: #05b18b solid 1px;
    color:white;
    display: grid;
    place-items: center;
    background-color: white;
  }
  
  .sidebar div {
    cursor: pointer;
    position: absolute;
    left: 1em;
    top: 1em;
    width:90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sidebar div a{
    /* margin-right: 10%; */
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    text-decoration: none;
    /* cursor: pointer; */
  }
  
  button{
    padding: 10px;
    font-size: 2.5vmin;
    border: #05b18b solid 1px;
    /* background: #097159; */
    border-radius: 10px;
    cursor: pointer;
    /* color: #dcdddc; */
    font-weight: 500;
  }

