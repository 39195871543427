@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&family=Inter:wght@300&family=Lato:ital@1&family=Lobster+Two&family=Montserrat&family=Nunito:wght@400;500&family=Open+Sans&family=Poppins&family=Rajdhani:wght@400;700&family=Roboto&family=Saira+Extra+Condensed:wght@500;600;700&family=Secular+One&family=Source+Serif+Pro:ital,wght@0,200;1,400&display=swap');

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    list-style: none;
}

body {
    /* background-image: url('./assets/images/Device.bmp'); */
    /* background-repeat: no-repeat; */
}

.display {
    position: absolute;
    margin-top: 14.1%;
    width: 246px;
    height: 254px;
    background-repeat: no-repeat;
    transform: rotate(0deg);
}

.display img {
    width: 100%;
}

.heading {
    position: absolute;
    left: 29%;
    top: 3%;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2.2vmin;
    line-height: 126%;
    color: #000000;
}

.heading2 {
    position: absolute;
    left: 45%;
    top: -5%;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2.2vmin;
    line-height: 5%;
    color: #000000;
}

.heading1 {
    position: absolute;
    left: 88%;
    top: 11%;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2vmin;
    line-height: 126%;
    color: #fff;
}

.display ul li {
    list-style-type: none;
    padding-left: 10px;
}

.display ul, .tab-content {
    position: absolute;
    left: 3%;
    top: 14%;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2vmin;
    line-height: 28px;
    color: #fff;
    width: 90%;
}

.row-view {
    display: flex;
    flex-direction: row;
}

.display ul .first {
    background: #D9D9D9;
    border-radius: 6px;
    color: #000000;
    width: 137%;
    border: 2px solid #000;
}

.tab-buttons {
    display: none;
}

.tab-buttons button.active {
    background-color: red;
}

.tab-content .tab-pane, .tab-pane1 {
    color: white;
    border-bottom: 1px solid black;
    width: 100%;
    padding-left: 10px;
}

.tab-content1 .tab-pane1 {
    border-bottom: 0;
    border-right: 1px solid black;
    border-left: 1px solid #000;
    padding: 10px;
}

.tab-content .tab-pane.active {
    color: black;
    background: #D9D9D9;
    border-radius: 6px;
    color: #000000;
    width: 100%;
    border: 1px solid #000;
    padding-left: 10px;
}

.tab-content1 .tab-pane1.active {
    color: black;
    background: #D9D9D9;
    border-radius: 6px;
    color: #000000;
    width: 138%;
    border-right: 1px solid #000;
    border-left: 1px solid #000;
}

.display ul, .tab-content1 {
    position: absolute;
    left: 3%;
    top: 30%;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 2vmin;
    line-height: 126px;
    color: #fff;
}

.meter {
    display: flex;
    align-items: center;
    position: absolute;
    left: -9%;
    top: 27%;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 3.2rem;
    line-height: 126px;
    color: #fff;
    text-align: center;
}

.controls {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.digits {
    display: flex;
    align-items: center;
    border-bottom: 0;
}

.digits span {
    cursor: pointer;
    font-size: 5vmin;
    border-right: 2px solid #000;
    padding: 10px;
}

.digits span.active {
    color: black;
    background: #D9D9D9;
    border-radius: 6px;
    color: #000000;
    width: 138%;
}

.controls button {
    padding: 5px 10px;
    font-size: 16px;
}

.back-button {
    position: absolute;
    top: 18%;
    left: 235%;
    padding: 6%;
}

#mobile-portrait {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 999999;
    display: none;
}

.center-device {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) scale(2.5);
    transform: translate(-50%, -50%) scale(2.5);
    height: 50vh;
    max-height: 250px;
}

@media only screen and (min-device-width: 0px) and (max-device-width: 912px) and (orientation: portrait) {
    #mobile-portrait {
        display: block !important;
    }
}
