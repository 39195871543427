#change-syringe{
    /* position: absolute; 
    left: 4%;
    top: 60%;
    width: 8%;
    height: 9%;
    border-radius: 0%; */
    position: absolute;
    left: 5vw;
    top: 54vh;
    width: 15vmin;
    height: 10vmin;
    border-radius: 0%;
}