.bg-white {
    /* background-color: rgba(0, 180, 130, 0.75) !important; */
    background-image: url("/public/Rectangle 5.png");
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Bold";
    height: 100vh;
    overflow: auto;
    background-color: #002c19;
    /* background-image: url('Rectangle 5.png'); */
    /* background: radial-gradient(
        circle,
        rgba(2, 0, 36, 1) 0%,
        rgba(30, 54, 59, 1) 0%,
        rgba(93, 93, 116, 1) 100%
      ); */
  }
  @font-face {
    font-family: "Bold";   /*Can be any text*/
    src: local("SourceSerifPro"),
      url("../Fonts/Rotis Sans Serif Std 75 Extra Bold.otf") format("truetype");
  }
  .form-bg {
  }
  input {
    width: 100%;
  }
  .container {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    /* max-width: 500px; */
    /* width: 70%; */
    margin: auto;
  }
  .container > form {
    width: 70%;
    border: 1px solid black;
    padding: 20px;
    border-radius: 20px;
    /* background: #fff; */
    /* background: rgba(0, 180, 130, 0.8); */
    background: rgb(0 180 130 / 11%);
  }
  pre {
    width: 70%;
    color: #fff;
    font-size: larger;
  }
  
  .btnlogin {
    /* background: #0563b4 !important; */
    width: 50%;
    background-color: rgba(0, 113, 81, 1) !important;
  }
  p {
    color: red;
  }
  
  input {
    border: 18px;
    background-color: rgba(255, 255, 255, 1);
    padding: 6px;
  }
  .logo {
    margin-top: 20%;
  }
  .bbraun-img {
    width: 100%;
  }
  
  .card2 {
    background-color: rgba(0, 180, 130, 0.75);
    border-radius: 50px;
    padding: 15%;
  }
  h1 {
    color: white;
    margin-bottom: 12%;
  }
  .card {
    background-color: rgba(0, 0, 255, 0);
    border: 1px solid transparent;
    padding: 10%;
  }
  
  .rounded-start {
    background-color: rgba(0, 0, 255, 0);
    padding: 20%;
  }
  
  .btnlogin {
    color: white;
  }
  