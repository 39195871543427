#changing-infusion-ok-btn{
  position: absolute;
  left: 32%;
  top: 36%;
  height: 13%;
  width: 4%;
  border-radius: 0%;
}

#ddb-ok-btn{
  /* position: absolute;
  left: 62%;
  top: 30%;
  height: 20%;
  width: 7%;
  border-radius: 0%; */
  position: absolute;
    left: 62vw;
    top: 30vh;
    height: 20vmin;
    width: 12vmin;
    border-radius: 0%;
}