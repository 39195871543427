#standby-left{
    /* position: absolute;
    left: 27%;
    top: 36%;
    height: 11%;
    height: 9%;
    width: 4%; */
    position: absolute;
    left: 27vw;
    top: 33vh;
    height: 11%;
    height: 18vmin;
    width: 10vmin;
   
}

#standby-ok{
    position: absolute;
    left: 4%;
    top: 43%;
    width:5%;
    height:11%;

}