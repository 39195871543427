.display_ddb2{
    
        position: relative;
        width: 18%;
        height: 62%;
        /* left: 10.1%; */
        margin-top: 14.1%;
        /* left: 9.1%; */
        margin-left: 8.6%;
        width: 16%;
        max-height: 28%;
        background-color: #00665b;
        /* left: 9.1%; */
        margin-top: 8.52%;
        /* margin-top: 14.1%; */
        /* background-image: url(./assets/images/Group\ 2.png); */
        background-repeat: no-repeat;
        transform: rotate(0deg);
        border: 4px solid white;
        border-radius: 4px;
    
}

.display_ddb2 ul li {
    list-style-type: none;
    padding-left: 10px;
}
.display_ddb2 ul,
.tab-content {
    position: absolute;
    left: 3%;
    top: 14%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 2vmin;
    line-height: 28px;
    color: #fff;
    /* display: none; */
}

/* .row-view {
    display: flex;
    flex-direction: row;
} */

.display_ddb2 ul .first {
    background: #D9D9D9;
    border-radius: 6px;
    color: #000000;
    width: 137%;
    border: 2px solid #000;
}

.display_ddb2 ul,
.tab-content1 {
    position: absolute;
    left: 3%;
    top: 30%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 3.2rem;
    line-height: 126px;
    color: #fff;
}

.heading_ddb2 {
    position:initial;
    /* width: 99px;
  height: 17px; */
    left: 31%;
    top: 3%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
        font-size: 2.2vmin;
        background-color: #8bceab;

    line-height: 126%;
    color: #000000;
 
}