/* mobile.css */
/* @media only screen and (min-device-width: 658px) and (max-device-width: 740px) and (orientation: landscape) {
  .ok-button {
    top: 10vh !important;
}
} */
/* Styles for screens with a maximum width of 768px (typical for mobile portrait mode) */
@media only screen and (min-device-width: 0px) and (max-device-width: 800px) and (orientation: landscape) {
  #tooltip1{
    font-size:5vmin !important;

  }
  #tooltip{
    font-size:4vmin !important;
  }
  #changing-infusion-ok-btn{
    top: 33% !important;
    height: 13%;
  }
  #ddb-ok-btn{
    /* top: 28% !important; */
    top: 28vh !important;
    height: 13%;
  }
  #on1, #on2{
    top: 43% !important;
  }
  #ok1, #ok2, #ok3{
    top: 32vh !important;
    width: 10vmin !important;
  }
  .up-button{
    /* top: -9%;
    left: 151%;  */
    /* top: 0vh;
        left: 23vw; */
        top: -4vh;
        left: 23vw;
  }

  .up-button1{
    top: -38%;
    /* left: 196%; */
    left: 146%;
  }
    /* Adjust the styling of your components for mobile here */
    .container-fluid {
      /* Modify container styles for mobile */
      padding: 10px;
    }
    .shiftright{
      /* left: 194%;
      top: 3%; */
      left: 27vw;
        top: 2vh;
    }
 
    .ok-vr{
      top:3%
    }
    .left{
      /* top: 1%;
      left: 130%; */
      top: 2vh;
        left: 18vw;
    }
    /* Adjust the styles for tab buttons */
    .tab-buttons {
      /* Center align the tabs horizontally */
      text-align: center;
    }
    .display_menu{
      height: 41%; 
    margin-top: 14.1%;
    margin-left: 8.6%;
    width: 16%;
    max-height: 24%;
    background-color: #00665b;
    /* margin-top: 3.52%; */
    margin-top: 8%;
    }

    .display_tabs{
      height: 41%;
      margin-top: 14.1%;
      margin-left: 6.6%;
      width: 17.5%;
      max-height: 24%;
      background-color: #00665b;
      /* margin-top: 3.52%; */
      margin-top: 6%;
    }
    .display_meter {
      height: 41%; 
      margin-top: 14.1%;
      margin-left: 7.6%;
      width: 16%;
      max-height: 24%;
      background-color: #00665b;
      /* margin-top: 3.52%; */
      margin-top: 5%;
    }
    .display_ddb2{
      max-height: 25% !important;
      margin-top: 4.4% !important;
     
    }
    .display ul, .tab-content {
        position: absolute;
        /* left: 3%;*/
        top: 14%; 

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 2vmin;
        line-height: 11px !important;
        color: #fff;
        width: 96%;
        /* display: none; */
    }
    .display1{
        margin-top: 12.6% !important;

    }

    .display2{
      margin-top: 7.3%!important;
    }

    .display1t{
      margin-top: 4.5% !important;  
    }

    .heading{
        left: 27%;
    top: 0% !important;
    font-size: 2.5vmin;
    line-height: 13px !important;
    }
    .displayVid {
      margin-top: 12.6%;
      /* margin-top: 13.8%; */
  }

  .heading2{
    left: 45%;
    top: -8% !important;
    font-size: 3vmin;
  }

  .heading1{
    top: 15% !important;
  }

  /* .digits span{

  } */
  .display3 {

    margin-top: 5.6%!important;
 
}

.digits p span{
  font-size: 5vmin !important;
}

.tab,.tab1 {
  width: 13px;
}
.meter {
  left: -3%;
  top: 33%;
  font-size: 2vmin;
  /* line-height: 66px; */
  line-height: 33px;
  /* margin-top: 10px; */
}

.startinfusion{
  padding: 5px;
  /* position: relative; */
  /* top: -38px; */
  font-size: 2vmin;
  margin-top: 23vh;
}
.startinfusion span{
  font-size: 2vmin;
}


  .up-button1 {
    /* top: -87%; */
    /* left: 196%; */
    /* left: 159%; */
    top: -7vh;
        /* left: 196%; */
        left: 23vw;
}

.down-button1 {
  /* top: 90%;
  left: 161%; */
  top: 8vh;
        left: 23vw;
}

.ok-button1 {

  left: 153%;
  
}
#lefttime{
    
  top: 7%;
  left: 152%;

}

#up-buttontime{
  top: -84%;
  left: 191%;
}

#down-buttontime{
  top: 92%;
  left: 191%;
}

#shiftrighttime{
  left: 229%;
  top: 7%;
}

.ok-overv{
  top: 1%;
}
.ok-button {
  
  top: 7vh !important;
}
#bolus-left-btn{
  /* top: 20% !important; */
  top: 22vh !important;
}
#standby-left{
  /* left: 28% !important;
  top: 33% !important; */
  left: 26vw !important;
        top: 27vh !important;
}

.start-button1{
  left: 357%;
  top: -10%
}

}

  @media only screen and (min-device-width: 801px) and (max-device-width:1000px) and (orientation: landscape) {
    #tooltip1{
      font-size:4vmin !important;
  
    }
    #tooltip{
      font-size:3.5vmin !important;
    }
    #on1, #on2{
      top: 47% !important;
    }

    #ok1, #ok2, #ok3{
      top: 36% !important;
    }
    .display_menu{

    /* margin-top: 14.1%; */
    max-height: 29%;
    margin-top: 9.52%;
    }

    .display_tabs{

      /* margin-top: 14.1%; */
      max-height: 29%;
      margin-top: 8.52%;

      }
  

    .display_ddb2{

      /* margin-top: 14.1%; */
      max-height: 29%;
      margin-top: 7.52% !important;
      }
    .display1t {
      margin-top: 7.5% !important;
  }
    .display ul, .tab-content {

    
      font-size: 2vmin;
      line-height: 17px !important;
    
      /* display: none; */
  }
  .display1{
      margin-top: 13.6% !important;

  }
  .display3 {
    /* margin-top: 8.6%!important; */
    margin-top: 7.5%!important;

}

.shiftright{
  /* left: 181%;
    top: 3%; */
    left: 26vw;
        top: 1vh;
}
.left{
  /* top: 1%;
    left: 116%; */
    top: 1vh;
        left: 17vw;
}
  .heading{
      left: 27%;
  top: 0% !important;
  font-size: 2.5vmin;
  line-height: 24px !important;
  }
  .displayVid {
    margin-top: 12.6%;
    /* margin-top: 13.8%; */
}

.digits span{
  font-size: 5vmin !important;
}

.tab, .tab1{
  width: 17px;
}
.meter {

  left: -1%;
    /* top: 14%; */
    top: 33%;
    font-size: 2vmin;
    /* line-height: 66px; */
    line-height: 35px;

}
.heading2{
  left: 41%;
    top: 0%;
 
    font-size: 3vmin;
}

.heading1{
  font-size: 2vmin;


    line-height: 0px;
    position: absolute;
    top: 20%;
}
.startinfusion{
  padding: 3px;
    position: relative;
    top: -36px;
    font-size: 2.5vmin;
    margin-top: 86%;
}
.start {
  border-radius: 50%;
  padding: 4px 1px 4px 1px;
  font-size: 2.5vmin;
}
.up-button1 {
  /* top: -87%; */
  /* left: 196%; */
  /* left: 147%; */
  top: -8vh;
  /* left: 196%; */
  left: 21vw;
}
.down-button1 {
  /* top: 77%;
  left: 149%; */
  top: 10vh;
        left: 21vw;
}
.ok-vr{
  top:13%
}

#lefttime{
    
  top: 7%;
  left: 152%;

}

#up-buttontime{
  top: -84%;
  left: 191%;
}

#down-buttontime{
  top: 92% !important;
  left: 191% !important;
}

#shiftrighttime{
  left: 229% !important;
  top: 7% !important;
}
#bolus-left-btn{
  top: 20% !important;
}
#standby-left{
  /* left: 28% !important;
  top: 38% !important; */
  left: 27vw !important;
  top: 33vh !important;
}

.start-button1{
  left: 357%;
  top: 2%;
}
  }

  @media only screen and (min-device-width: 1001px) and (max-device-width:1368px) and (orientation: landscape) {
    #tooltip1{
      font-size:5vmin !important;
  
    }
    #tooltip{
      font-size:3vmin !important;
    }
    #changing-infusion-ok-btn{
      top: 21% !important;
    }
    #ddb-ok-btn{
      top: 21% !important;
    }
    #on1, #on2{
      top: 31% !important;
    }

    #ok1, #ok2, #ok3{
      top: 23% !important;
    }
    .down-button, .up-button, .ok-button{
      left: 123% !important;
      width: 45% !important;
    }
    #bolus-left-btn{
      top: 10% !important;
    }
    #bolus-btn, #bolus-btn2{
      top: 18% !important;
    }
    .display_menu {
      position: relative;
    height: 62%;
    margin-top: 14.1%;
    margin-left: 11.6%;
    width: 21%;
    max-height: 25%;
    margin-top: 10.52%;
   
  }

  .display_tabs {
    position: relative;
  height: 62%;
  margin-top: 14.1%;
  margin-left: 11.6%;
  width: 21%;
  max-height: 25%;
  margin-top: 10.52%;
 
}
  .display_meter{
    position: relative;
    height: 62%;
    margin-top: 14.1%;
    margin-left: 8.6%;
    width: 21%;
    max-height: 25%;
    margin-top: 9.52%;
  }
  .display_ddb2{
    position: relative;
    height: 62%;
    width: 21% !important;
    max-height: 25% !important;
    margin-left: 11.6% !important;
    margin-top: 7.52% !important;
  }

  #drug-db1 {
    
    top: 12% !important;
  }

  #standby-left{
    left: 27% !important;
    top: 24% !important;
  }

  .startinfusion {
    padding: 5px;
    position: relative;
    top: -80px;
    margin-top: 88%;
}
.start-button1{
left: 326%;

top: 5%;
}
}
  