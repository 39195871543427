@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&family=Inter:wght@300&family=Lato:ital@1&family=Lobster+Two&family=Montserrat&family=Nunito:wght@400;500&family=Open+Sans&family=Poppins&family=Rajdhani:wght@400;700&family=Roboto&family=Saira+Extra+Condensed:wght@500;600;700&family=Secular+One&family=Source+Serif+Pro:ital,wght@0,200;1,400&display=swap');
body {
    background-image: url('../../assets/images/Zoom out with syringe copy.jpg');
    background-repeat: no-repeat;
    overflow: hidden;
}
.bg-syringe{
    background-image: url('../../assets/images/Zoom out with syringe copy.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height:100vh;
}

.bg-change-syringe{
    background-image: url('../../assets/images/Syringe Change-full.png');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height:100vh;
}
svg{
   fill:'#03b289';
}

.menu-button{
    position: absolute;
    top: -6%;
    left: 228%;
    color:transparent
}
.display1 {
    position: absolute;
    /* width: 307px;
    height: 244px;
    left: 9%;
    top: 29.2%; */
    width: 246px;
    height: 254px;
    /* left: 129px !important; */
    /* top: 29.7%; */
    /* margin-top: 9.5% !important; */
    margin-top: 13.6% !important;
  
    /* margin-top: 7.8% !important; */
    /* background-image: url('./assets/images/Group\ 2.png'); */
    background-repeat: no-repeat;
    transform: rotate(0deg);
}
.display1t{
  margin-top: 7.8% !important;  
}
.display1m{
    position: absolute;
    /* width: 307px;
    height: 244px;
    left: 9%;
    top: 29.2%; */
    width: 246px;
    height: 254px;
    margin-top: 13.8% !important;
}

.display1 img {
    /* width: 100%; */
    width: 80%;
}

.heading_menu {
    position:initial;
    /* width: 99px;
  height: 17px; */
    left: 31%;
    top: 3%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
        font-size: 2.2vmin;
        background-color: #8bceab;

    line-height: 126%;
    color: #000000;
 
}

.heading2 {
    position: absolute;
    /* width: 99px;
  height: 17px; */
    left: 45%;
    top: 3%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
        font-size: 2.2vmin;


    line-height: 5%;
    color: #000000;
  
}
.display_menu {
    position: relative;
    width: 18%;
    height: 62%;
    /* left: 10.1%; */
    margin-top: 14.1%;
    /* left: 9.1%; */
    margin-left: 8.6%;
    width: 16%;
    max-height: 28%;
    background-color: #00665b;
    /* left: 9.1%; */
    margin-top: 9.52%;
    /* margin-top: 14.1%; */
    /* background-image: url(./assets/images/Group\ 2.png); */
    background-repeat: no-repeat;
    transform: rotate(0deg);
    border: 4px solid white;
    border-radius: 4px;
}

.display_tabs {
    position: relative;
    width: 18%;
    height: 62%;
    /* left: 10.1%; */
    margin-top: 14.1%;
    /* left: 9.1%; */
    margin-left: 8.6%;
    width: 16%;
    max-height: 28%;
    background-color: #00665b;
    /* left: 9.1%; */
    margin-top: 9.52%;
    /* margin-top: 14.1%; */
    /* background-image: url(./assets/images/Group\ 2.png); */
    background-repeat: no-repeat;
    transform: rotate(0deg);
    border: 4px solid white;
    border-radius: 4px;
}

.heading1 {
    position: absolute;
    /* width: 99px;
  height: 17px; */
    left: 88%;
    top: 11%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 2vmin;



    line-height: 126%;
    color: #fff;
}


/* .tab-buttons {
  display: flex;
  flex-direction: row;
} */

.display_menu ul li {
    list-style-type: none;
    padding-left: 10px;
}

.display_menu ul,
.tab-content {
    position: absolute;
    left: 3%;
    top: 14%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
    color: #fff;
    /* display: none; */
}

.row-view {
    display: flex;
    flex-direction: row;
}

.display_menu ul .first {
    background: #D9D9D9;
    border-radius: 6px;
    color: #000000;
    width: 137%;
    border: 2px solid #000;
}

.tab-buttons {
    /* background-color: white; */
    display: none;
}


/* .tab-buttons {
  display: flex;
  flex-direction: row;
} */

.tab-buttons button.active {
    background-color: red;
}

.tab-content .tab-pane,
.tab-pane1 {
    color: white;
    border-bottom: 1px solid black;
    width: 100%;
    padding-left: 10px;
}

.tab-content1 .tab-pane1 {
    border-bottom: 0px;
    border-right: 1px solid black;
    border-left: 1px solid #000;
    padding: 10px;
}

.tab-content .tab-pane.active {
    /* Styles for active tab content */
    color:transparent;
    background: #D9D9D9;
    border-radius: 6px;
    color: #000000;
    width: 100%;
    border: 1px solid #000;
    padding-left: 10px;
}

.tab-content1 .tab-pane1.active {
    color:transparent;
    background: #D9D9D9;
    border-radius: 6px;
    color: #000000;
    width: 103%;
    /* border:1px solid #000; */
    padding-left: 10px;
    border-right: 1px solid #000;
    border-left: 1px solid #000;
}

.display_menu ul,
.tab-content1 {
    position: absolute;
    left: 3%;
    top: 30%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 3.2rem;
    line-height: 126px;
    color: #fff;
}

.meter {
    display: flex;
    align-items: center;
    position: absolute;
    left: -9%;
    top: 27%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 3.2rem;
    line-height: 126px;
    color: #fff;
}

.controls {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.digits {
    display: flex;
    align-items: center;
    border-bottom: 0px;
    /* padding: 10px; */
}

.digits span {
    cursor: pointer;
    /* margin: 0 5px; */
    font-size: 3rem;
    border-right: 2px solid #000;
    /* border-left:2px solid #000; */
    padding: 10px;
}

.digits span.active {
    color:transparent;
    background: #D9D9D9;
    border-radius: 6px;
    color: #000000;
    width: 138%;
    /* border:1px solid #000; */
}

.controls button {
    padding: 5px 10px;
    font-size: 16px;
}
.down-button, .up-button, .ok-button{
    position: absolute;
    left: 21vw;
    /* border-radius: 50%; */
    height: 8vmin !important;
    /* width: 20% !important; */
    background-color: transparent;
    color: transparent;
    border: 0px;
}

/* h3{
    font-size: 5vmin !important;
}

h4{
    font-size: 4vmin !important; 
} */
.up-button{   
    /* top: 1%; */
    top: 0vh;

}

.ok-button{
    height: 29%;
    /* width: 20%; */
    
    top: 36%;

}

.down-button{ 
    top: 68%;
}



.up-buttonm{   
    top: -2%;
}

.ok-buttonm{
    top: 21%;
}

.tab-content {
    max-height: 300px; /* Adjust the height as needed */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds the max-height */
  }
  .tab-content .tab-pane {
    display: none; /* Hide all tab panes by default */
  }
  
  .tab-content .tab-pane.active {
    display: block; /* Show the active tab pane */
  }

  /* Add this to your CSS */

.special-tab {
    text-align: center;
    color: #ffffffae !important; /* or any other lighter color */
    position: relative;
    padding: 0 0px; /* Add padding to create space for arrows */
  }
  

  
  .special-tab::before,
  .special-tab::after {
    content: 'ᐊ'; /* Left arrow (U+2190) */
    font-size: 1.2em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0.5;
    color:#00655a;
    background-color: white !important;
    /* border-radius: 50%; */
    padding:0px 9px;
  }
  .special-tab::before {
    content: 'ᐊ'; /* Right arrow (U+2192) */
    left: 10px; /* Adjust this value to increase or decrease the gap */
  } 
  .special-tab::after {
    content: '\1405'; /* Right arrow (U+2192) */
    right: 10px; /* Adjust this value to increase or decrease the gap */
  }
  
  .tab-content .special-tab.active{
    background-color:transparent !important;
    border: 1px solid #D9D9D9 !important;
  }

  /* .left{
    top: -12%;
    left: 147%;
    top: 19% !important;
    left: 116% !important;
} */

.left-shift-button{
    
    /* top: 7% !important;
    left: 152% !important; */

}

.shiftright{
    /* left: 224%;
    top: -13%; */
    /* left: 169% !important;
    top: 19% !important; */
}

/* .right-shift-button{
    left: 229%;
    top: 7%;
} */