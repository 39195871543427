@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&family=Inter:wght@300&family=Lato:ital@1&family=Lobster+Two&family=Montserrat&family=Nunito:wght@400;500&family=Open+Sans&family=Poppins&family=Rajdhani:wght@400;700&family=Roboto&family=Saira+Extra+Condensed:wght@500;600;700&family=Secular+One&family=Source+Serif+Pro:ital,wght@0,200;1,400&display=swap');
body {
    background-image: url('../../assets/images/REVISED DEVICE.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}
.back-button{
    position: absolute;   
    top: 24%;
    left: 221%;
    padding: 6%;
    border-radius: 50%;
    color: rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
    border:0;
  }
.display {
    /* width: 246px;
    height: 244px;
    left: 9%;
    top: 29.2%; */
    margin-top: 14.1%;
    width: 246px;
    height: 254px;
    left: 9.1%;
    /* top: 29.7%; */

    /* background-image: url(./assets/images/Group\ 2.png); */
    background-repeat: no-repeat;
    transform: rotate(0deg);
}
.display2{
    /* margin-top: 9.5% !important; */
    /* margin-top: 9.3%!important;
    margin-left: 9% !important; */
    margin-top: 8.6%!important;
    margin-left: 0% !important;
}
/* .display2{
margin-top: 13.9% !important;
} */
.displayVol{
    margin-top: 14%;
}

.displayTime{
    margin-top: 13.6% !important;
}
.display img {
    width: 100%;
}



.display3{
    /* margin-top: 9.4% !important;
    margin-left: 9% !important; */
    /* margin-top: 8.6%!important; */
    /* margin-top: -1.4%!important;
    margin-left: 0% !important; */
    margin-top: 8%!important;
    margin-left: 0% !important;
}

.heading {
    position: absolute;
    /* width: 99px;
  height: 17px; */
    left: 31%;
    top: 4%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
        font-size: 2.2vmin;


    line-height: 126%;
    color: #000000;
    color: #000000;
}

.heading2 {
    position: absolute;
    /* width: 99px;
  height: 17px; */
    /* left: 45%;
    top: 3%; */
    left: 42%;
    top: 4%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
        font-size: 2.2vmin;


    line-height: 5%;
    color: #000000;
    color: #000000;
}

.heading1 {
    position: absolute;
    /* width: 99px;
  height: 17px; */
  left: 83%;
  top: 11%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 2vmin;



    line-height: 126%;
    color: #fff;
}

.heading11 {
    position: absolute;
    /* width: 99px;
  height: 17px; */
  left: 7%;
  top: 15%;
    font-family: 'Inter';
    font-style: normal;
        font-size: 2.2vmin;


    line-height: 126%;
    color: #fff;
}

.display_meter {
    position: relative;
    width: 18%;
    height: 62%;
    /* left: 10.1%; */
    margin-top: 14.1%;
    /* left: 9.1%; */
    margin-left: 8.6%;
    width: 16%;
    max-height: 28%;
    background-color: #00665b;
    /* left: 9.1%; */
    margin-top: 8.52%;
    /* margin-top: 14.1%; */
    /* background-image: url(./assets/images/Group\ 2.png); */
    background-repeat: no-repeat;
    transform: rotate(0deg);
    border: 4px solid white;
    border-radius: 4px;
}

/* .tab-buttons {
  display: flex;
  flex-direction: row;
} */

.display ul li {
    list-style-type: none;
    padding-left: 10px;
}

.display ul,
.tab-content {
    position: absolute;
    left: 3%;
    top: 14%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 2vmin;

    line-height: 28px;
    color: #fff;
    /* display: none; */
}

.row-view {
    display: flex;
    flex-direction: row;
}

.display ul .first {
    background: #D9D9D9;
    border-radius: 6px;
    color: #000000;
    width: 137%;
    border: 2px solid #000;
}

.display ul,
.tab-content1 {
    position: absolute;
    left: 3%;
    top: 30%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 126px;
    color: #fff;
}

.meter {
    display: flex;
    align-items: center;
    position: absolute;
    left: 10%;
    top: 26%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 87px;
    color: #fff;
    text-align: center;
}

.controls {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.digits {
    display: flex;
    align-items: center;
    border-bottom: 0px;
    /* padding: 10px; */
}

.digits span {
    cursor: pointer;
    /* margin: 0 5px; */
    font-size: 5vmin;
    border-right: 2px solid #000;
    /* border-left: 2px solid #000; */
    padding: 0px;
   

}
.start{
    border-radius: 50%;
    padding:6px 2px 6px 2px;
    font-size:12px;
}
.startinfusion{
    padding:5px;
    position: relative;
    top: -38px;
    margin-top: 78%;
    /* border-left:4px solid white;
    border-right:4px solid white; */
}

.tab{
    width:27px;
}
.tab1{
    width:33px;
}
.lefttime{
    
    top: 7%;
    left: 152%;

}

.up-buttontime{
    top: -84%;
    left: 191%;
}

.down-buttontime{
    top: 92%;
    left: 191%;
}

.shiftrighttime{
    left: 229%;
    top: 7%;
}

.digits span.active {
    color:transparent;
    background: #D9D9D9;
    border-radius: 6px;
    color: #000000;
    /* width: 68%; */
    width:36%;
    /* border: 1px solid #000;
    /* border:1px solid #000; */
}

.controls button {
    padding: 5px 10px;
    font-size: 16px;
}

.down-button1, .up-button1, .ok-button1, .left, .shiftright {
    position: absolute;
    /* border-radius: 50%; */
    height: 53%;
    width: 30%;
    /* background-color:transparent;
    color:transparent; */
    background-color:transparent;
    color:transparent;
    border: 0px;
}
.down-button1 {
    top: 108%;
    left: 166%;
}

.up-button1 {
    top: -42%;
    /* left: 196%; */
    left: 166%;
}

.ok-button1{
    top: 32%;
    left: 142%;
    height: 32%;
}

.left {
    top: 34%;
    left: 130%;
}

.shiftright {
    left: 201%;
    top: 34%;
}

.vid1{
    position: relative;
    width: 240px;
    margin-top: 8.8%;
    margin-left: 9%;
}

.start-button1{

    /* left: 337%; */
    /* left: 349%;
    top: 12%; */
    
    background-color: transparent;
    border: 0;
    color:transparent;
    left: 352%;
    padding: 19px;
    position: absolute;
    top: 12%;
}


.headingOverview{
    position: absolute;
    /* width: 99px;
  height: 17px; */
  left: 38%;
  top: 3%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 2.2vmin;
    line-height: 17px;
    color: #000000;
    color: #000000;
}
.value1-part {
    font-weight: bold;
    /* Add other CSS properties as desired */
  }
  
  .value1-unit {
    /* Add CSS properties for the 'h' and 'min' units */
  }

  .tab1:nth-child(6){
font-size:16px;
color: black;
/* background-color: white; */
  }

  .tab1:nth-child(3){
    font-size:16px;
      } 